<template>
  <div>
    <Investor_KYC_Form
      :company_id="company_id"
      :user_id="$store.getters['Auth/getAuthUser'].id"
      :form_unique_id="form_unique_id"
      :view_type="view_type"
      v-if="form_unique_id === 'investor-kyc-2023-v1'"
    />
  </div>
</template>

<script>
import Investor_KYC_Form from "@/components/Investor_KYC_Form";

export default {
  name: "ReadForm",

  components: {
    Investor_KYC_Form,
  },

  props: ["form_unique_id", "company_id"],

  data: () => ({
    user_id: null,
    view_type: "logged_in",
  }),
};
</script>
